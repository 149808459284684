/**
 * Cursor JavaScript singleton module
 */

var Cursor = (function($) {

  function initCursor() {
    $(document).on('mousemove', function(e) {

        $('#cursor').css({
          left: e.pageX,
          top: e.pageY
        });


        if($(".bg--white:hover").length != 0){
            $("#cursor").css({'border-color':'#06615E', 'mix-blend-mode' : 'normal'});
        } else if ($('#cookie-law-info-bar:hover').length != 0) {
            $("#cursor").css({'border-color':'#06615E', 'mix-blend-mode' : 'normal'});
        } else if ($('.home-offers__slide.slick-current:hover').length != 0) {
            $("#cursor").css({'border-color':'#F4F3EC', 'mix-blend-mode' : 'normal'});
        } else if($(".hero__image:hover").length != 0) {
            $("#cursor").css({'border-color':'#F4F3EC', 'mix-blend-mode' : 'difference'});
        } else if ($(".image--circle img:hover").length != 0) {
            $("#cursor").css({'border-color':'#F4F3EC', 'mix-blend-mode' : 'difference'});
        } else if($(".image__wrapper img:hover").length != 0){
            $("#cursor").css({'border-color':'#F4F3EC', 'mix-blend-mode' : 'difference'});
        } else if ($(".bg--grey:hover").length != 0) {
            $("#cursor").css({'border-color':'#06615E', 'mix-blend-mode' : 'normal'});
        } else if ($(".bg--dark:hover").length != 0){
            $("#cursor").css({'border-color':'#F4F3EC', 'mix-blend-mode' : 'normal'});
        }  else if ($('.bg--light-2:hover').length != 0) {
            $("#cursor").css({'border-color':'#F4F3EC', 'mix-blend-mode' : 'normal'});
        } 

      });
 }

  function init() {

    
    initCursor();

  }

  return {
    init: init
  };

}(jQuery));

jQuery(document).ready(Cursor.init);

/**
 * Sliders JavaScript singleton module
 */

var Sliders = (function($) {

  function initCenterSliders() {
    $('.slider--center').slick({
        centerMode: true,
        centerPadding: '0px',
        slidesToShow: 3,
        rows: 0,
        dots: false,
        infinite: true,
        cssEase: 'linear',
        speed: 400,
        slide: '.slide',
        prevArrow: $('.slider--prev'),
        nextArrow: $('.slider--next'),
        responsive: [
          {
            breakpoint: 578,
            settings: {
              slidesToShow: 1,
            }
          }
        ]
    })
  }

  function initDuoSliders() {
    $('.slider--duo').each(function(){
      $(this).slick({
        slidesToShow: 1,
        rows: 0,
        dots: false,
        infinite: false,
        cssEase: 'linear',
        speed: 400,
        slide: '.slide',
        prevArrow: $(this).find('.slider--prev'),
        nextArrow: $(this).find('.slider--next'),
      })
    })
    
  }

  function init() {

    // return early if no corresponding module present
    if (!$('.slider').length) {
      return;
    } 

    if ($('.slider--center').length) {
        initCenterSliders();
    }

    if ($('.slider--duo').length) {
      initDuoSliders();
    }
  }

  return {
    init: init
  };

}(jQuery));

jQuery(document).ready(Sliders.init);

/**
 * Animations JavaScript singleton module
 */

var Animations = (function($) {
  
  gsap.registerPlugin(ScrollTrigger);

  function sliderCircle() {

    ScrollTrigger.create({
        trigger: ".home-offers",
        start: "top 50%",
        end: "bottom 100px",
        onEnter: () => $('.slider-circle').addClass('animate'),
      });
  }

  function contactCircle() {

    ScrollTrigger.create({
        trigger: ".contact-circle",
        start: "top 50%",
        end: "bottom 100px",
        onEnter: () => $('.contact-circle').addClass('animate'),
      });
  }

  function ofertasCircle() {

    ScrollTrigger.create({
        trigger: ".ofertas-circle",
        start: "top 50%",
        end: "bottom 100px",
        onEnter: () => $('.ofertas-circle').addClass('animate'),
      });
  }

  function footerCircle() {

    ScrollTrigger.create({
        trigger: ".footer__row",
        start: "top 95%",
        //end: "bottom 100px",
        onEnter: () => $('.footer-circle').addClass('animate'),
      });
  }

  function splitText() {
    $( document ).ready( function() {
      let revealText = document.querySelectorAll(".reveal-text");
      let results = Splitting({ target: revealText, by: "lines" });
    
      results.forEach((splitResult) => {
        const wrappedLines = splitResult.lines
          .map(
            (wordsArr) => `
            <span class="line"><div class="words">
              ${wordsArr
                .map(
                  (word) => `${word.outerHTML}<span class="whitespace"> 
             </span>`
                )
                .join("")}
            </div></span>`
          )
          .join("");
        splitResult.el.innerHTML = wrappedLines;
      });
    
        let revealLines = revealText.forEach((element) => {
        const lines = element.querySelectorAll(".line .words");

        let tl = gsap.timeline({
          scrollTrigger: {
            trigger: element,
            toggleActions:"play none none none",
          }
        });
        tl.set(revealText, { autoAlpha: 1 });

        tl.from(lines, 0.65, {
          yPercent: 100,
          ease: Power3.out,
          stagger: 0.25,
          delay: 0.2
        });

      
      });
    });
  }

  function testimonialSlide() {
    let imageSlides = gsap.utils.toArray('.home-testimonials__image');
    let textSlides = gsap.utils.toArray('.home-testimonials__block');

    imageSlides.forEach((imageSlide) => {
    let tl = gsap.timeline({
      scrollTrigger: {
        trigger: imageSlide,
        toggleActions:"play none none none",
      }
    });

    tl.set(imageSlide, { autoAlpha: 1 });
    tl.to(imageSlide, 1.25, {
      yPercent: 10,
      ease: Power2.easeOut,
      stagger: 0.25,
      delay: 0.25
    });
    })

    textSlides.forEach((textSlide) => {
      let tl2 = gsap.timeline({
        scrollTrigger: {
          trigger: textSlide,
          toggleActions:"play none none none",
        }
      });
  
      tl2.set(textSlide, { autoAlpha: 1 });
      tl2.to(textSlide, 1.25, {
        yPercent: -10,
        ease: Power2.easeOut,
        stagger: 0.25,
        delay: 0.25
      });
      })

  };

  function ofertasSlide() {
    let slider = gsap.utils.toArray('.home-offers__slider');
  
    let tl = gsap.timeline({
      scrollTrigger: {
        trigger: slider,
        toggleActions:"play none none none",
      }
    });

    tl.from(slider, 1.5, {
      yPercent: 10,
      autoAlpha: 1,
      ease: Power2.easeInOut,
      stagger: 0.25,
      delay: 0.15
    });
  };

  function homeMetas() {
    var sections = gsap.utils.toArray(".home-metas").forEach(function(elem) {

      var blocks = elem.querySelectorAll(".home-metas__block");
    
      var tl = gsap.timeline({
        scrollTrigger: {
          trigger: elem,  
          start: "top 50%",         
          toggleActions: "play none none none"
        },
      })
      .from(blocks, {yPercent: 100,  duration: 1.5, ease: Power2.easeInOut, stagger:0.2}) 
      
      })
    
  };

  function homeHero(){
    let revealContainers = document.querySelectorAll(".home-hero__image");

    revealContainers.forEach((container) => {
      let image = container.querySelector("img");
      let tl = gsap.timeline({
        scrollTrigger: {
          trigger: container,
          toggleActions: "restart none none reset"
        }
      });

      tl.set(container, { autoAlpha: 1 });
      tl.from(container, 1.5, {
        xPercent: -100,
        ease: Power2.easeOut,
      });
      tl.from(image, 1.5, {
        xPercent: 100,
        scale: 1.3,
        delay: -1.5,
        ease: Power2.easeOut,
      });
      
    });
  }

  function ofertasHero(){
    let heroText = document.querySelectorAll(".ofertas-hero__subheading");

    let tl = gsap.timeline({
      scrollTrigger: {
        trigger: heroText,
        toggleActions: "restart none none reset"
      }
    });

    tl.fromTo(heroText, {autoAlpha: 0}, {autoAlpha: 1, duration: 0.75, delay: 1});
    tl.fromTo(heroText, {yPercent: 10}, {yPercent: 0, duration: 1, delay: -1});

  }

  function equipoHero(){
    let revealContainers = document.querySelectorAll(".equipo-hero__image");
    let heroText = document.querySelectorAll(".equipo-hero__desc");

    revealContainers.forEach((container) => {
      let image = container.querySelector("img");
      let tl = gsap.timeline({
        scrollTrigger: {
          trigger: container,
          toggleActions: "restart none none reset"
        }
      });

      tl.set(container, { autoAlpha: 1 });
      tl.from(container, 1.5, {
        xPercent: -100,
        ease: Power2.easeOut,
      });
      tl.from(image, 1.5, {
        xPercent: 100,
        scale: 1.3,
        delay: -1.5,
        ease: Power2.easeOut,
      });
      
      tl.fromTo(heroText, {autoAlpha: 0}, {autoAlpha: 1, duration: 0.75, delay: 1});
      tl.fromTo(heroText, {yPercent: 10}, {yPercent: 0, duration: 1, delay: -1});
      
    });

    let revealSubContainers = document.querySelectorAll(".equipo-sub-hero__image");
    let subheroText = document.querySelectorAll(".equipo-sub-hero__desc");

    revealSubContainers.forEach((container) => {
      let image = container.querySelector("img");
      let tl = gsap.timeline({
        scrollTrigger: {
          trigger: container,
          toggleActions: "restart none none reset"
        }
      });

      tl.set(container, { autoAlpha: 1 });
      tl.from(container, 1.5, {
        xPercent: 100,
        ease: Power2.easeOut,
      });
      tl.from(image, 1.25, {
        xPercent: -100,
        scale: 1.3,
        delay: -1.75,
        ease: Power2.easeOut,
      });

      tl.fromTo(subheroText, {autoAlpha: 0}, {autoAlpha: 1, duration: 0.75, delay: 1});
      tl.fromTo(subheroText, {yPercent: 10}, {yPercent: 0, duration: 1, delay: -1});
      
    });
  }

  function equipoMembers() {
    var sections = gsap.utils.toArray(".equipo-grid").forEach(function(elem) {
    var blocks = elem.querySelectorAll(".equipo-grid__item");
  
    var tl = gsap.timeline({
      scrollTrigger: {
        trigger: elem,  
        start: "top 80%",         
        toggleActions: "play none none none"
      },
    })

    .from(blocks, {yPercent: 100,  duration: 1.5, ease: Power2.easeInOut, stagger:0.2}) 
      
    })
  };

  function contactIntro(){
    let revealContainers = document.querySelectorAll(".contact-intro__image");
    let heroText = document.querySelectorAll(".contact-intro__subheading");
    let footerList = document.querySelectorAll(".footer__list");
    

    revealContainers.forEach((container) => {
      let image = container.querySelector("img");
      let tl = gsap.timeline({
        scrollTrigger: {
          trigger: container,
          toggleActions: "restart none none reset"
        }
      });

      tl.from(image, 1, {
        yPercent: 10,
        delay: 0,
        ease: Power2.easeOut,
        stagger: 1
      });

      tl.fromTo(heroText, {autoAlpha: 0}, {autoAlpha: 1, duration: 0.75});
      tl.fromTo(heroText, {yPercent: 10}, {yPercent: 0, duration: 1, delay: -1});

      tl.fromTo(footerList, {autoAlpha: 0}, {autoAlpha: 1, duration: 0.75});
      tl.fromTo(footerList, {yPercent: 10}, {yPercent: 0, duration: 1, delay: -1});

    })};

    

  function init() {

    if ($('.slider-circle').length) {
        sliderCircle();
    }

    if ($('.contact-circle').length) {
        contactCircle();
    }

    if ($('.ofertas-circle').length) {
        ofertasCircle();
    } 

    footerCircle();
    

      splitText();

  

    if ($('.home-testimonials__slide').length) {
      testimonialSlide();
    }

    if ($('.home-offers__slider').length) {
      ofertasSlide();
    }

    if ($('.home-metas').length) {
      homeMetas();
    }

    if ($('.home-hero').length) {
      homeHero();
    }

    if ($('.ofertas-hero').length) {
      ofertasHero();
    }

    if ($('.equipo-hero').length) {
      equipoHero();
    }

    if ($('.equipo-grid').length) {
      equipoMembers();
    }

    if ($('.contact-intro').length) {
      contactIntro();
    }

  }

  return {
    init: init
  };

}(jQuery));

jQuery(document).ready(Animations.init);

/**
 * Circle JavaScript singleton module
 */

var Circles = (function($) {


  function initCircleOfertas() {
    headerHeight = $('.header').height();
    mainHeight = $('.page-template-template-ofertas .main').height() / 3;
    heroOfertasHeight = $('.ofertas-hero').height();


    if ($(window).width() > 576) {
        $('.ofertas-circle').css('height', mainHeight);
        $('.ofertas-circle').css('width', mainHeight);
        $('.ofertas-circle').css('right', -mainHeight / 2);

    } else {
        $('.ofertas-circle').css('height',heroOfertasHeight * 4);
        $('.ofertas-circle').css('width', heroOfertasHeight * 4);
    }
    
  }

  function initCircleEquipo() {
    headerHeight = $('.header').height();
    herosHeight = $('.page-template-template-equipo .equipo-heros').height();
    herosWidth = $('.page-template-template-equipo .equipo-heros').width();
    $('.equipo-circle').css('height', herosHeight * 2);
    $('.equipo-circle').css('width', herosHeight * 2);
  }

  function initCircleSlider() {
    
    circleSliderHeight = $('.home .home-offers').height();
    circleSliderWidth = $('.home .home-offers').width();
    $('.slider-circle').css('height', circleSliderWidth);
    $('.slider-circle').css('width', circleSliderWidth);
  }

  function init() {

    // return early if no corresponding module present
    if ($('.ofertas-circle').length) {
          setTimeout(function() { 
            initCircleOfertas();
        }, 600);
        

        $(window).on('resize', function(){
            initCircleOfertas();
        });
    } 

    if ($('.equipo-circle').length) {
        initCircleEquipo();

        $(window).on('resize', function(){
            initCircleEquipo();
        });
    } 

    if ($('.slider-circle').length) {
        initCircleSlider();

        $(window).on('resize', function(){
            initCircleSlider();
        });
    } 

    
    

    
    

  }

  return {
    init: init
  };

}(jQuery));

jQuery(document).ready(Circles.init);

/**
 * Header JavaScript singleton module
 */

var Header = (function($) {

    var c, currentScrollTop = 0;

    function stickytHeader() {
        
        const a = $(window).scrollTop();
        const b = $('.header').height();

        currentScrollTop = a;

        if (c < currentScrollTop && a > b + b) {
            $('body').removeClass("sticky-nav");
        } else if (c > currentScrollTop && !(a <= b)) {
            $('body').addClass("sticky-nav");
        }
        c = currentScrollTop;

        // Hide Sticky Nav when header is visible

        if ($(window).scrollTop() > 200) {
            $('.header').addClass('header--has-scrolled')
        
        } else {
            // The top header is visible - hide the sticky nav
            $('body').removeClass("sticky-nav");
            $('.header').removeClass('header--has-scrolled')
        }
    }

    function init() {

        // return early if no corresponding module present
        if (!$('.header').length) {
        return;
        }

        $(window).scroll(function() {
            stickytHeader();
        });
    }

  return {
    init: init
  };

}(jQuery));

jQuery(document).ready(Header.init);
